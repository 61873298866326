import React from 'react';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function EngCutManagement() {
    return (
        <>
            <Header current="一覧" />
            <CommonManagement
                endpoint={["/cars"]}
                dropdownOptions={[
                    { value: 'carUserName', label: '賃借人名' },
                    { value: 'number', label: 'ナンバー' },
                    { value: '4-digitFrameNumber', label: '車台番号(下4桁)' },
                    { value: 'frameNumber', label: '車台番号(全一致)' },
                ]}
                tableHeaders={[
                    '車台番号',
                    '車種',
                    'ナンバー',
                    '賃借人名',
                    '電話番号',
                    'エンジンOFF/ON',
                    '最後に取得できた位置情報',
                    '走行軌跡',
                    '車載器管理番号',
                    '所属代理店',
                ]}
                tableRowData={(car) => [
                    car.frame_number,
                    car.car_name,
                    car.car_number,
                    car.userName || '未登録',
                    car.phoneNumber || '未登録',
                    car.status.eng_cut_relay === 1 
                        ? 'エンジンOFF' 
                        : car.status.eng_cut_relay === 0 
                            ? 'エンジンON' : '不明',
                    'lastLocation',
                    'track',
                    car.setting.tcu_code,
                    car.renterName,
                ]}
                approvalFlg="1"
                option="noCheckbox"
            />
        </>
    );
}

export default EngCutManagement;
