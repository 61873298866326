import React from 'react';
import { useLocation } from 'react-router-dom';
import CommonManagement from '../../components/common-management';
import EngCutModal from '../modals/eng-cut-modal';
import Header from '../../components/header';

function EngCutManagement() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search); // クエリパラメータをパース
    const isEngineCutOn = searchParams.get('engCutOn') === 'true'; // engineCutOnの値を取得
    return (
        <>
            <Header current="エンジン停止システム" />
            <CommonManagement
                endpoint={["/cars"]}
                dropdownOptions={[
                    { value: 'carUserName', label: '賃借人名' },
                    { value: 'number', label: 'ナンバー' },
                    { value: '4-digitFrameNumber', label: '車台番号(下4桁)' },
                    { value: 'frameNumber', label: '車台番号(全一致)' },
                ]}
                tableHeaders={[
                    '車台番号',
                    '車種',
                    'ナンバー',
                    '賃借人名',
                    'エンジンOFF/ON', 
                ]}
                tableRowData={(car) => [
                    car.frame_number,
                    car.car_name,
                    car.car_number,
                    car.userName || '未登録',
                    car.status.eng_cut_relay === 1 
                        ? 'エンジンOFF' 
                        : car.status.eng_cut_relay === 0 
                            ? 'エンジンON' : '不明',
                ]}
                DetailModal={EngCutModal}
                approvalFlg="1"
                buttonType="setting"
                option={isEngineCutOn ? 'isEngCutOn' : 'engCut'}
            />
        </>
    );
}

export default EngCutManagement;
