import React, { useEffect, useState, useContext } from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, CircularProgress, Pagination, IconButton, Tooltip, Select, MenuItem } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { AuthContext } from '../auth/AuthProvider';
import TrackComponent from './map/track-component';
import LastLocationComponent from './map/last-location-component';

function ResultsTable({ displayedList = [], selectedItem, setSelectedItem, tableHeaders, tableRowData, option, isLoading }) {
    const { auth } = useContext(AuthContext);
    const loginUser = auth.user;
    const [filters, setFilters] = useState({});
    const [showMapItem, setShowMapItem] = useState(null);
    const [showLastLocationMap, setShowLastLocationMap] = useState(false);
    const [showTrackMap, setShowTrackMap] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterInputVisible, setFilterInputVisible] = useState({});
    const rowsPerPage = 20; // 1ページあたりの表示件数

    // displayedList が変わったときに1ページ目に戻る
    useEffect(() => {
        setCurrentPage(1);
    }, [displayedList]);

    // 管理番号を取得
    const getItemCode = (item) => {
        if (item.car_name) return item.car_code;
        if (item.user_name) return item.user_code;
        if (item.admin_code) return item.admin_code;
        if (item.renter_name) return item.renter_code;
        if (item.tcu_code) return item.tcu_code;
        return item.code;
    };

    // フィルター処理
    const handleFilterChange = (header, value) => {
        setFilters((prevFilters) => ({ ...prevFilters, [header]: value }));
    };

    // フィルター入力の表示切り替え
    const toggleFilterInput = (header) => {
        setFilterInputVisible((prev) => ({ ...prev, [header]: !prev[header] }));
    };

    // フィルター処理
    const filteredList = displayedList.filter((item) => {
        return tableHeaders.every((header, index) => {
            const filterValue = filters[header];
            if (!filterValue) return true;

            const cellValue = tableRowData(item)[index];
            return cellValue && cellValue.toString() === filterValue;
        });
    });

    // 現在のページに応じて表示するデータ
    const paginatedList = filteredList.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    // チェックボックスの選択処理
    const handleCheckboxChange = (item) => {
        setSelectedItem((prevSelectedItems) => {
            const isSelected = prevSelectedItems.some((selected) => getItemCode(selected) === getItemCode(item));
            return isSelected ? [] : [item];
        });
    };

    // 全選択処理
    const handleSelectAllChange = () => {
        setSelectedItem(selectedItem.length === displayedList.length ? [] : displayedList);
    };

    // 最終位置情報の表示
    const handleLastLocation = (item) => {
        setShowMapItem(item);
        setShowLastLocationMap(true);
    };

    // 位置情報の軌跡の表示
    const handleTrack = (item) => {
        setShowMapItem(item);
        setShowTrackMap(true);
    };

    // ページ変更処理
    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <TableContainer component={Paper} sx={{ marginY: 1 }}>
            <Table size="small">
                <TableHead sx={{ backgroundColor: '#e8e6e6' }}>
                    <TableRow>
                        {(option !== "noCheckbox" 
                        && !(["tcu", "tcu-setting"].includes(option) && loginUser.authorityLevel !== 4)) 
                        && (
                            <TableCell padding="checkbox">
                                {option !== "engCut" && option !== "tcu-setting" && (
                                    <Checkbox
                                        onChange={handleSelectAllChange}
                                        checked={displayedList.length !== 0 && selectedItem.length === displayedList.length}
                                    />
                                )}
                            </TableCell>
                        )}
                        {tableHeaders.map((header, index) => (
                            <TableCell key={index}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {header}
                                    {(header === '始動方法' || header === 'エンジンOFF/ON') ? (
                                    <Tooltip title="フィルター">
                                        <IconButton size="small" onClick={() => toggleFilterInput(header)}>
                                            <FilterListIcon />
                                        </IconButton>
                                    </Tooltip>
                                    ) : (
                                    <></>
                                    )}
                                </div>
                                {filterInputVisible[header] ? (
                                    <Select
                                        size="small"
                                        value={filters[header] || ''}
                                        onChange={(e) => handleFilterChange(header, e.target.value)}
                                        displayEmpty
                                        sx={{ marginTop: 1, width: '100%' }}
                                    >
                                        <MenuItem value="">全て</MenuItem>
                                        {header === '始動方法' ? [
                                            <MenuItem key="cylinder" value="シリンダー">シリンダー</MenuItem>,
                                            <MenuItem key="push" value="プッシュ">プッシュ</MenuItem>,
                                        ] : null}
                                        {header === 'エンジンOFF/ON' ? [
                                            <MenuItem key="stop" value="エンジンOFF">エンジンOFF</MenuItem>,
                                            <MenuItem key="resume" value="エンジンON">エンジンON</MenuItem>,
                                        ] : null}
                                    </Select>
                                ) : (
                                    filterInputVisible[header] && (
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            placeholder="フィルター"
                                            value={filters[header] || ''}
                                            onChange={(e) => handleFilterChange(header, e.target.value)}
                                            sx={{ marginTop: 1 }}
                                        />
                                    )
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={tableHeaders.length + 1} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    ) : paginatedList.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={tableHeaders.length + 1} align="center">
                                該当なし
                            </TableCell>
                        </TableRow>
                    ) : (
                        paginatedList.map((item, index) => (
                            <TableRow key={index}>
                                {(option !== "noCheckbox" 
                                && !(["tcu", "tcu-setting"].includes(option) && loginUser.authorityLevel !== 4)) 
                                && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedItem.some((selected) => getItemCode(selected) === getItemCode(item))}
                                            onChange={() => handleCheckboxChange(item)}
                                        />
                                    </TableCell>
                                )}
                                {tableRowData(item).map((data, index) => (
                                    <TableCell key={index}>
                                        {data === 'lastLocation' ? (
                                            item.status?.latitude && item.status?.longitude ? (
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    style={{ marginLeft: 5, textDecoration: 'underline', padding: 0, minWidth: 'unset', textTransform: 'none' }}
                                                    onClick={() => handleLastLocation(item)}
                                                >
                                                    Googleマップで表示
                                                </Button>
                                            ) : '未登録'
                                        ) : data === 'track' ? (
                                            item.status ? (
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    style={{ marginLeft: 5, textDecoration: 'underline', padding: 0, minWidth: 'unset', textTransform: 'none' }}
                                                    onClick={() => handleTrack(item)}
                                                >
                                                    Googleマップで表示
                                                </Button>
                                            ) : '未登録'
                                        ) : (
                                            data || '未登録'
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>

            <Pagination
                count={Math.ceil(filteredList.length / rowsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                sx={{ display: 'flex', justifyContent: 'center', marginY: 1 }}
            />

            {showLastLocationMap && (
                <LastLocationComponent
                    car={showMapItem}
                    onClose={() => setShowLastLocationMap(false)}
                />
            )}
            {showTrackMap && (
                <Dialog open onClose={() => setShowTrackMap(false)} fullWidth>
                    <DialogTitle>位置情報の軌跡</DialogTitle>
                    <DialogContent>
                        <TrackComponent
                            tcuCode={showMapItem.status.tcu_code}
                            onClose={() => setShowTrackMap(false)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowTrackMap(false)}>閉じる</Button>
                    </DialogActions>
                </Dialog>
            )}
        </TableContainer>
    );
}

export default ResultsTable;
