import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Container, Grid, Paper } from '@mui/material';
import { AuthContext } from '../auth/AuthProvider'; 
import Header from '../components/header';
import DashboardItems from '../components/home/dashboard-items';
import ActionButtons from '../components/home/action-buttons';
import ErrorLogs from '../components/home/error-logs';
import LoadingSpinner from '../components/loading-spinner';

function Home() {
    const { auth } = useContext(AuthContext);
    const loginUser = auth.user;
    const [counts, setCounts] = useState({
        carCount: 0,
        userCount: 0,
        unapprovedCarCount: 0,
        unapprovedUserCount: 0,
        starterCutOnCount: 0,
        errorCarCount: 0,
    });
    const [errorLogs, setErrorLogs] = useState([]);
    const [errorLogsLoading, setErrorLogsLoading] = useState(true);
    const [dashboardLoading, setDashboardLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!loginUser || auth.isLoading) return;
                const apiUrl = process.env.REACT_APP_API_URL;
                // ダッシュボードの情報を取得
                const dashboardResponse = await axios.get(`${apiUrl}/dashboard`, { params: { loginUser : loginUser } })
                setCounts(dashboardResponse.data);
                setDashboardLoading(false);

                // エラーログの情報を取得
                const errorResponse = await axios.get(`${apiUrl}/errorLogs`, { params: { loginUser: loginUser } })
                setErrorLogs(errorResponse.data);
                setErrorLogsLoading(false);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [loginUser, auth]);

    if (auth.isLoading) {
        return (
            <LoadingSpinner />
        );
    }

    const buttons = [
        { to: "/eng-cut-search", label: "エンジンOFF/ON", roles: [1,2,3,4] },
        { to: "/entry", label: "エントリー", roles: [2,3,4] },
        { to: "/unapproved", label: "未承認一覧", roles: [2,3,4] },
        { to: "/car-management", label: "車両管理", roles: [3,4] },
        { to: "/user-management", label: "賃借人管理", roles: [3,4] },
        { to: "/error-management", label: "エラー車両管理", roles: [3,4] },
        { to: "/tcu-management", label: "車載器管理", roles: [3,4] },
        { to: "/admin-management", label: "管理者管理", roles: [2,3,4] },
        { to: "/renter-management", label: "代理店管理", roles: [3,4] },
        { to: "/tcu-setting", label: "車載器設定", roles: [3,4] },
        { to: "/ota-search", label: "OTA", roles: [4] },
        { to: "/list", label: "稼働一覧", roles: [2,3,4] },
    ]

    const dashboardItems = [
        { title: '車両', count: counts.carCount, unit: '台' },
        { title: '賃借人', count: counts.userCount, unit: '人' },
        { title: '未承認車両', count: counts.unapprovedCarCount, unit: '台' },
        { title: '未承認賃借人', count: counts.unapprovedUserCount, unit: '人' },
        { title: 'エンジンOFF車両', count: counts.starterCutOnCount, unit: '台' },
        { title: 'エラー車両', count: counts.errorCarCount, unit: '台' },
    ];

    return (
        <>
            <Header current="ホーム" />
            <Container maxWidth="xl" sx={{ marginTop: 2 }}>
                {loginUser && loginUser.authorityLevel !== 1 && (
                    <Paper sx={{ padding: 2, marginBottom: 2, backgroundColor: '#e8e5e5' }}>
                        <Grid container spacing={3} justifyContent="center">
                            {dashboardItems.map((item) => (
                                <DashboardItems 
                                    key={item.title}
                                    title={item.title} 
                                    count={item.count} 
                                    unit={item.unit}
                                    isLoading={dashboardLoading} 
                                />
                            ))}
                        </Grid>
                    </Paper>
                )}

                <ActionButtons buttons={buttons} userAuthority={loginUser.authorityLevel} />

                {loginUser && loginUser.authorityLevel !== 1 && (
                    <ErrorLogs errorLogs={errorLogs} isLoading={errorLogsLoading} />
                )}
            </Container>
        </>
    );
}

export default Home;
