import React from 'react';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function ErrorManagement() {
    return (
        <>
            <Header current="車両管理" />
            <CommonManagement
                endpoint={["/cars/error"]}
                dropdownOptions={[
                    { value: 'carUserName', label: '賃借人名' },
                    { value: 'number', label: 'ナンバー' },
                    { value: '4-digitFrameNumber', label: '車台番号(下4桁)' },
                    { value: 'frameNumber', label: '車台番号(全一致)' },
                ]}
                tableHeaders={['車台番号', 'ナンバー', '賃借人名', 'エラー内容', 'エラー発日時']}
                tableRowData={(car) => [
                    car.frame_number,
                    car.car_number || '未登録',
                    car.user_name || '未登録',
                    car.error_name,
                    car.datetime,
                ]}
                approvalFlg="1"
                option="noCheckbox"
            />
        </>
    );
}


export default ErrorManagement;
