import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { AuthContext } from '../auth/AuthProvider'; 

// メニューデータ
const buttons = [
    { to: "/home", label: "ホーム", roles: [1, 2, 3, 4] },
    { to: "/list", label: "稼働一覧", roles: [2, 3, 4] },
    { to: "/eng-cut-search", label: "エンジンOFF/ON", roles: [1, 2, 3, 4] },
    { to: "/entry", label: "エントリー", roles: [2, 3, 4] },
    { to: "/unapproved", label: "未承認一覧", roles: [2, 3, 4] },
    { to: "/car-management", label: "車両管理", roles: [3, 4] },
    { to: "/user-management", label: "賃借人管理", roles: [3, 4] },
    { to: "/error-management", label: "エラー車両管理", roles: [3, 4] },
    { to: "/tcu-management", label: "車載器管理", roles: [3, 4] },
    { to: "/admin-management", label: "管理者管理", roles: [2, 3, 4] },
    { to: "/renter-management", label: "代理店管理", roles: [3, 4] },
    { to: "/tcu-setting", label: "車載器設定", roles: [3, 4] },
    { to: "/ota-search", label: "OTA", roles: [4] },
];

const Sidebar = () => {
    const { auth } = useContext(AuthContext);
    const location = useLocation(); // 現在のページの URL を取得
    const [isOpen, setIsOpen] = useState(true);

    const drawerWidth = isOpen ? '160px' : '40px';

    const toggleSidebar = () => setIsOpen(prevState => !prevState);

    return (
        <Drawer
            variant="permanent"
            open={isOpen}
            sx={{
                width: drawerWidth,
                '& .MuiDrawer-paper': { width: drawerWidth },
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                <IconButton onClick={toggleSidebar}>
                    { isOpen ? <ArrowBackIosNewIcon /> : <MenuIcon /> }
                </IconButton>
            </Box>
            <List>
                {auth.user && buttons
                    .filter(button => button.roles.includes(auth.user.authorityLevel))
                    .map((button) => {
                        const isActive = location.pathname === button.to; // 現在のURLがボタンのリンクと一致するか確認
                        return (
                            <ListItem key={button.to} disablePadding>
                                <ListItemButton 
                                    component={Link} 
                                    to={button.to}
                                    sx={{ 
                                        backgroundColor: isActive ? '#d1cecd' : 'inherit',
                                        '&:hover': {
                                            backgroundColor: isActive ? '#d1cecd' : '#f0f0f0'
                                        },
                                    }}
                                >
                                    <ListItemText 
                                        primary={button.label} 
                                        sx={{ display: isOpen ? 'block' : 'none' }} 
                                    />
                                </ListItemButton>
                            </ListItem>
                        );
                    })
                }
            </List>
        </Drawer>
    );
};

export default Sidebar;
